import 'ol/ol.css';
import { Fill, Stroke, Style, RegularShape } from 'ol/style';
import { get as getProjection, fromLonLat } from 'ol/proj';
import { register } from 'ol/proj/proj4';
import Feature from 'ol/Feature';
import GeoJSON from 'ol/format/GeoJSON';
import Control from 'ol/control/Control';
import Map from 'ol/Map';
import proj4 from 'proj4';
import Point from 'ol/geom/Point';
import {circular} from 'ol/geom/Polygon';
import TileLayer from 'ol/layer/Tile';
import ImageLayer from 'ol/layer/Image';
import TileWMS from 'ol/source/TileWMS';
import ImageWms from 'ol/source/ImageWMS';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import View from 'ol/View';

proj4.defs(
  'EPSG:25832',
  '+proj=utm +zone=32 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '
);
register(proj4);

var projection = getProjection('EPSG:25832');

var backgroundLayer1 = new ImageLayer({
  extent: [270000, 5.415e+06, 520000, 5.645e+06],
  source: new ImageWms({
    url: 'https://geo5.service24.rlp.de/wms/liegenschaften_rp.fcgi',
    params: {
      'LAYERS': 'GebaeudeBauwerke,Flurstueck,Lagebezeichnungen',
      'VERSION': '1.1.1',
      'STYLES': ',,',
      'FORMAT': 'image/png',
      'BGCOLOR': '0xffffff',
      'TRANSPARENT': 'TRUE',
      'EXCEPTIONS': 'application/vnd.ogc.se_xml'
    },
    transition: 0
  }),
});

var backgroundLayer2 = new TileLayer({
  extent: [152800.764919, 5354563.068124, 591244.156327, 5715346.438794],
  source: new TileWMS({
    url: 'https://geo4.service24.rlp.de/wms/rp_dop40.fcgi',
    params: {
      'LAYERS': 'rp_dop40'
    },
    transition: 0
  }),
  opacity: 0.75
});

var map = new Map({
  layers: [backgroundLayer2, backgroundLayer1],
  target: 'map',
  view: new View({
    center: [387848.5, 5603781.1],
    zoom: 19,
    projection: projection
  }),
});

var positionFeatureSource = new VectorSource();
navigator.geolocation.watchPosition(function(pos) {
  const coords = [pos.coords.longitude, pos.coords.latitude];
  const accuracy = circular(coords, pos.coords.accuracy);
  positionFeatureSource.clear(true);
  positionFeatureSource.addFeatures([
    new Feature(accuracy.transform('EPSG:4326', map.getView().getProjection())),
    new Feature(new Point(fromLonLat(coords, projection)))
  ]);
}, function(error) {
  alert(`ERROR: ${error.message}`);
}, {
  enableHighAccuracy: true
});

var stroke = new Stroke({color: 'darkgreen', width: 2});
var fill = new Fill({color: 'rgba(0, 109, 0, 0.25)',});

var l2 = new VectorLayer({
  source: positionFeatureSource,
  style: new Style({
    stroke: stroke,
    fill: fill,
    image: new RegularShape({
      fill: fill,
      stroke: stroke,
      points: 4,
      radius: 10,
      radius2: 0,
      angle: 0,
    }),
  })
});
map.addLayer(l2);

var fs = {
  "type": "FeatureCollection",
  "features": [
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                386948.92,
                5603097.926
              ],
              [
                386853.075,
                5602999.992
              ],
              [
                386827.484,
                5602973.882
              ],
              [
                386829.055,
                5602972.908
              ],
              [
                386838.615,
                5602961.436
              ],
              [
                386960.934,
                5603086.367
              ],
              [
                386960.969,
                5603086.402
              ],
              [
                386948.92,
                5603097.926
              ]
            ]
          ]
        ]
      },
      "properties": {
        "OBJEKTART": "Flurstueck"
      },
      "id": "1"
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                388594.766,
                5602674.152
              ],
              [
                388569.667,
                5602673.717
              ],
              [
                388609.605,
                5602598.846
              ],
              [
                388623.889,
                5602599.499
              ],
              [
                388633.87,
                5602602.126
              ],
              [
                388594.766,
                5602674.152
              ]
            ]
          ]
        ]
      },
      "properties": {
        "OBJEKTART": "Flurstueck"
      },
      "id": "2"
    }
  ]
};

var vectorSource = new VectorSource({
  features: new GeoJSON().readFeatures(fs),
});

var l = new VectorLayer({
  source: vectorSource,
  style: new Style({
    stroke: new Stroke({
      color: 'yellow',
      width: 1,
    }),
    fill: new Fill({
      color: 'rgba(109, 0, 0, 0.5)',
    }),
  })
});

map.addLayer(l);

// control
const locate = document.createElement('div');
locate.className = 'ol-control ol-unselectable locate';
locate.innerHTML = '<button title="Locate me">◎</button>';
locate.addEventListener('click', function() {
  if (!positionFeatureSource.isEmpty()) {
    console.log(positionFeatureSource.getFeatures());
    
    map.getView().fit(positionFeatureSource.getExtent(), {
      maxZoom: 18,
      duration: 500
    });
  }
});
map.addControl(new Control({
  element: locate
}));
